import React, { useState, useEffect } from 'react';
import socketIOClient from "socket.io-client";
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import logo from './gamacrm-min.png';
import wslogo from './whatsapp.png';
import wp1 from './wp1.jpeg';
import wp2 from './wp2.jpeg';
import wp3 from './wp3.jpeg';
import './App.css';
import spinner from './spinner.svg';
var QRCode = require('qrcode.react');
//const config = require('./config');
const moment = require('moment');
moment.locale("es_cr");

console.log("Ambiente: " + process.env.REACT_APP_ENV);

function App() {
  const [data, setData] = useState({});
  const [paso, setPaso] = useState(0);
  const [apikey, setApikey] = useState("");
  const [pairingCode, setPairingCode] = useState("");
  const [worker, setWorker] = useState("");

  useEffect(() => {
    setApikey(key => {
      let urlKey = getQueryVariable("apikey");
      return (urlKey.length > 63 ? urlKey : key);
    });
    if (apikey.length > 63) {
      let urlget = process.env.REACT_APP_ENDPOINT + '/postapigetworker/' + apikey;
      console.log("URL: ", urlget);
      axios.get(urlget).then((response) => {
        console.log("Connecting Worker: ", response.data.Description);
        setWorker(response.data.Url);
        socketIOClient(response.data.Url, { query: { apiKey: apikey } }).on("getqr", data => {
          setData(d => {
            if (data && data.active === false) {
              setPaso(2);
            } else {
              setPaso(3);
            }
            return data;
          });
        });
      });
    }
  }, [apikey]);

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return ("");
  }

  function refreshPage() {
    window.location.reload(false);
  }

  if (paso === 0) {// Presentacion
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Pasos para vincular su Whatsapp con el API GAMA</p>
          <div className="pasoscontenedor">
            <img src={wp1} className="paso-img" alt="Paso 1" />
            <img src={wp2} className="paso-img" alt="Paso 2" />
            <img src={wp3} className="paso-img" alt="Paso 3" />
          </div>
          <div className="pasoscontenedor">
            <Button variant="outlined" onClick={() => {
              if (apikey.length > 60) {
                setPaso(2);
              } else {
                setPaso(1);
              }
            }} >Siguiente</Button>
          </div>
          <p> Build: {process.env.REACT_APP_BUILD} </p>
        </header>
      </div>
    );
  } else if (paso === 1) { // Solicitar QR
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Por favor ingrese el Apikey asignado a su número whatsapp
          </p>
          <div className="mitad">
            <TextField
              required
              onChange={e => setApikey(e.target.value)}
              label="ApiKey"
              className="textfield"
            />
          </div>
          <div className="pasoscontenedor">
            <Button variant="outlined" onClick={() => {
              if (apikey.length > 60) {
                setPaso(2);
              }
            }} >Siguiente</Button>
          </div>
          <p> Build: {process.env.REACT_APP_BUILD} </p>
        </header>
      </div>
    );
  } else if (paso === 2) { // Vincular
    if (pairingCode !== "") {
      return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Vincule por código su Whatsapp
              <img src={wslogo} className="wp-logo" alt="logo" />
            </p>
            <p>
              {data.error}
            </p>
            <p>
              +{data.client.Cel_phone}
            </p>
            <p>
              {data.client.Description}
            </p>
            <p>
              Código: {pairingCode || data.pairingCode || "Solicitando... por favor espere."}
            </p>
            <p>
              <Button variant="outlined" onClick={() => {
                setPaso(2);
                setPairingCode("");
              }} >Vincular con QR</Button>
            </p>
            <p> Build: {process.env.REACT_APP_BUILD} </p>
          </header>
        </div>
      );
    } else if (data.qr && data.qr.length > 200) {
      return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Escanee este QR para vincular su Whatsapp
              <img src={wslogo} className="wp-logo" alt="logo" />
            </p>
            <p>
              {data.error}
            </p>
            <p>
              +{data.client.Cel_phone}
            </p>
            <p>
              {data.client.Description}
            </p>
            <QRCode
              value={data.qr}
              size={340}
              includeMargin={true}
              imageSettings={{
                src: wslogo,
                height: 30,
                width: 30,
                x: 130, y: 130
              }}
            />
            <p>
              <Button variant="outlined" onClick={() => {
                setPaso(2);
                if (apikey.length > 63) {
                  axios.post(worker + '/pairingCodeRequested?apiKey=' + apikey).then((rcode) => {
                    setPairingCode(() => { return rcode.pairingCode; });
                  });
                }
              }} >Vincular con CÓDIGO</Button>
            </p>
            <p> Build: {process.env.REACT_APP_BUILD} </p>
          </header>
        </div>
      );
    } else {
      return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              {data.error}
              <img src={wslogo} className="wp-logo" alt="logo" />
            </p>
            <img src={spinner} className="App-logo" alt="logo" />
            <p> Build: {process.env.REACT_APP_BUILD} </p>
          </header>
        </div>
      );
    }
  } else if (paso === 3) { // Mensaje de exito
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Pasos para vincular su Whatsapp con el API GAMA</p>
          <div className="pasoscontenedor">
            {data.error}
          </div>
          <div className="pasoscontenedor">
            <Button variant="outlined" onClick={() => { refreshPage(); }} >Reintentar</Button>
          </div>
          <p> Build: {process.env.REACT_APP_BUILD} </p>
        </header>
      </div>
    );
  }
}

export default App;
